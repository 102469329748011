<template>
  <div>
    <TailwindComponentsCommandPalette
      :disabled="disabled"
      :isLanguage="customLanguage"
      :items="filteredLanguages"
      :initialSelected="initFilter"
      :selectable="true"
      :customList="true"
      :allowNew="true"
      :icon="icon"
      :label="label"
      :keepOneItem="customLanguage"
      :isSitesLangFilter="isSitesLangFilter"
      @selected="setLangArr"
      @search="getFilteredLanguage"
      @warning="warning"
    >
      <template v-slot:selected-item="{ selectedItem }">
        <span class="pr-2">{{ selectedItem[currentLocale.name] }}</span>
      </template>
      <template v-slot:item="{ item }">
        <div class="app-command-palette__content">
          <div class="app-command-palette__content__text">
            <strong>{{ item.alpha2 }}</strong
            >:
            <span>{{ item[currentLocale.name] }}</span>
          </div>
        </div>
      </template>
    </TailwindComponentsCommandPalette>
  </div>
</template>

<script>
import languageData from '~/assets/language-codes.json'

export default {
  data() {
    return {
      filteredLanguages: [],
      languageArr: [],
    }
  },
  props: {
    customLanguage: {
      type: Boolean,
      default: false,
    },
    initFilter: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: 'filter',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    initFilter(value) {
      this.languageArr = this.initFilter
    },
  },
  computed: {
    currentLocale() {
      let lang = this.$i18n.locales
        .filter(i => i.code === this.$i18n.locale)
        .pop()

      if (!languageData[0][lang?.name]) {
        lang = { code: 'en', iso: 'en', name: 'English', label: 'EN' }
      }
      return lang
    },
  },
  async mounted() {
    if (this.initFilter) {
      this.languageArr = this.initFilter
    }
  },
  methods: {
    warning() {
      const notification = {
        message: this.$t('Must have at least one language selected'),
        type: 'error',
      }
      this.$bus.emit('notification', notification)
    },
    setLangArr(value) {
      this.languageArr = value
      this.$emit('selected', this.languageArr)
    },
    getFilteredLanguage(text) {
      if (typeof text === 'string') {
        this.filteredLanguages = languageData.filter(option => {
          var values = Object.values(option)
          var flag = false
          values.forEach(val => {
            if (val?.toLowerCase().indexOf(text?.toLowerCase()) >= 0) {
              flag = true
              return
            }
          })
          if (flag) return option
        })
      }
    },
  },
}
</script>
